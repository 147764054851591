import { memo } from "react";
import { Image } from "primereact/image";

const DificultadOrganizarTiempo = () => (
  <div className="bg-Crayola rounded-lg px-5">
    <div className="flex items-center mb-1.5 w-full space-x-3.5">
      <div className="h-[150px] flex-1">
        <Image
          alt="Image"
          src={require("../../../assets/images/motivos_cancelacion/opcion01.png")}
          imageClassName="w-full h-full object-contain"
        />
      </div>
      <div className="flex flex-col flex-1 w-full space-y-5">
        <p className="text-white text-[11px] font-golos leading-[14px] text-left">
          Nuestra recomendación:
        </p>
        <p className="text-white text-[18px] font-golos-semibold leading-[23px] text-left">
          Tenemos rutinas cortas <span className="text-Orchid">(15 min)</span>
        </p>
      </div>
    </div>
  </div>
);

export default memo(DificultadOrganizarTiempo);