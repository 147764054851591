import React, { useCallback, useContext, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Image } from 'primereact/image';
import dayjs from 'dayjs';
import { ReactComponent as FlechaIcon } from "../../assets/icons/flecha-icon.svg";
import { ReactComponent as EtapaIcon } from "../../assets/icons/etapa-icon.svg";
import { ReactComponent as Icon01 } from "../../assets/icons/home/icon01.svg";
import { ReactComponent as Icon04 } from "../../assets/icons/home/icon04.svg";
import { ReactComponent as Icon03 } from "../../assets/icons/programas/icon03.svg";
import HeaderNav from '../../components/Header';
import SEO from '../../components/SEO';
import VideoPlayer from '../../components/Modal/VideoPlayer';
import VimeoPlayerInline from '../../components/VimeoPlayerInline';
import Grid from '../../components/Grid';
import { ButtonPrimary } from '../../components/Button';
import Loading from '../../components/Loading';
import CarouselProductosRecomendados from './components/CarouselProductosRecomendados';
import { getSubscription } from '../../api/client/mi_suscripcion';
import { getCategoriesCores } from '../../api/client/cores';
import { getPrograma } from '../../api/client/tu_programa';
import { externalLinks, socialMedia } from '../../config/app';
import GlobalContext from '../../contexts/GlobalContext';
import { useCustomState } from '../../hooks/useCustomState';
import { setCheckLevel } from '../../redux/reducers/authSlice';
import intersectionObserver from '../../utils/intersectionObserver';
import getDataVimeoApi from '../../utils/getDataVimeoApi';
import { isEmpty } from '../../utils';
import updateUserStore from '../../utils/updateUserStore';
import getImageWeserv from '../../utils/getImageWeserv';

const MiCuenta = () => {
  const elementsRef = useRef([]);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { setModal } = useContext(GlobalContext);
  const { user, checkLevel } = useSelector(state => state.app.auth);

  const [state, setState] = useCustomState({
    loading: true,
    isVideoVisible: false,
    portada_video_introduccion: null,
    video_introduccion: null,
    video_hoy: null,
    suscripcion_seleccionada: null,
    etapas: {
      etapa_1: null,
      etapa_2: null,
    },
  });

  useEffect(() => {
    const observer = intersectionObserver({
      elementsRef,
      callback: (entry) => {
        if (entry.isIntersecting && entry.target.dataset.animation) {
          entry.target.classList.add(entry.target.dataset.animation);
          observer.unobserve(entry.target);
        }
      }
    });

    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    updateUserStore({ id: user?.id, email: user?.email }, dispatch);
    // eslint-disable-next-line
  }, [user?.id, user?.email]);

  useEffect(() => {
    if (checkLevel === true) {
      setTimeout(_ => {
        setModal("seleccionar_nivel", null, null, { showModal: true });
        dispatch(setCheckLevel(false));
      }, 500);
    }
    // eslint-disable-next-line
  }, [checkLevel]);

  useEffect(() => {
    const fetchData = async () => {
      let stateUpdates = {};

      try {
        const suscripcion = await getSubscription({
          userID: user?.userID,
          programID: user?.programID,
        });
        stateUpdates.suscripcion_seleccionada = suscripcion?.data;
      } catch (error) { }

      // Load etapas
      try {
        const etapas = await getCategoriesCores({
          filters: {
            name: ["Etapa 1", "Etapa 2"]
          }
        });
        stateUpdates.etapas = {
          etapa_1: (etapas?.data?.find(item => (item?.name?.toLowerCase())?.indexOf("etapa 1") !== 1))?.id,
          etapa_2: (etapas?.data?.find(item => (item?.name?.toLowerCase())?.indexOf("etapa 2") !== 1))?.id,
        };
      } catch (error) { }

      // Load video hoy
      try {
        const fechaActual = dayjs().format("YYYY-MM-DD");
        if (!isEmpty(user?.programID)) {
          const _getPrograma = await getPrograma({
            populate: ["workouts"],
            group: "day",
            programID: user?.programID,
            "startDateDayWorkout[]['date']": fechaActual
          });
          const _getVideo = _getPrograma?.data?.CalendarDaysWorkouts?.[Object.keys(_getPrograma?.data?.CalendarDaysWorkouts)?.[0]]?.[0]?.Workout;
          stateUpdates.video_hoy = _getVideo;
        }
      } catch (error) { }

      // Search suscripcion data
      try {
        const baseUrl = "https://player.vimeo.com/video/";
        const suscripcionCodes = {
          "fitmom-embarazo": { id: 910136833 },
          "fitmom-posparto": { id: 912668975 },
          "fitmom-strength": { id: 912776190 }
        };
        const { code } = user?.Program || {};
        if (code && suscripcionCodes.hasOwnProperty(code)) {
          const { id } = suscripcionCodes[code];
          const url = `${baseUrl}${id}?autoplay=1`;
          try {
            const { data } = await getDataVimeoApi(id);
            const portada_video_introduccion = data?.video?.thumbs?.base ?? data?.pictures?.base_link;
            stateUpdates.portada_video_introduccion = portada_video_introduccion;
            stateUpdates.video_introduccion = { id, url };
          } catch (error) { }
        }
      } catch (error) { }

      stateUpdates.loading = false;
      setState(prevState => ({ ...prevState, ...stateUpdates }));
    };

    fetchData();
    // eslint-disable-next-line
  }, [user?.userID, user?.programID, user?.Program]);

  const getMessage = useCallback((program) => {
    let message;

    switch (program) {
      case "fitmom-embarazo":
        message = "Inicia con el nivel 1 de entrenamiento del core y complementa con el entrenamiento del día.";
        break;
      case "fitmom-posparto":
        message = "Sigue el orden de las etapas.";
        break;
      case "fitmom-strength":
        message = "Realiza el entrenamiento del día.";
        break;

      default:
        message = "Realiza el entrenamiento del día.";
        break;
    }

    return message;
  }, []);

  return (
    <>
      <SEO
        title="Mi cuenta"
        description="Fitness Coach Certificada Pre y Posnatal y Especialista en Ejercicios Correctivos, en Diástasis y en Core."
      />
      <VideoPlayer
        url={state.video_introduccion?.url}
        visible={state.isVideoVisible}
        onHide={() => setState({ isVideoVisible: false })}
        propsVideo={{
          controls: true
        }}
        propsContainer={{
          style: {
            height: 360
          }
        }}
      />
      <HeaderNav
        theme="white"
        modePromocion="internal"
      />

      <div className='bg-Magnolia'>
        <div className="xl:container mx-auto px-6 py-6 md:py-20">
          <Grid>
            {state.loading ? (
              <Loading />
            ) : (
              <div className='flex flex-col gap-4 mt-14 md:mt-0'>
                {(user?.subscription?.isActive !== true) ? (
                  <div className='bg-white px-8 py-6 rounded-xl'>
                    <p className='text-Crayola font-golos-medium text-center'>¡Lo sentimos!</p>
                    <p className='text-Crayola font-golos-medium text-center'>No cuentas con una suscripción activa</p>
                    <ButtonPrimary
                      text="¡Comprar suscripción!"
                      withIcon
                      classNameButton="text-xs"
                      styleContainer={{
                        display: "flex",
                        justifyContent: "center",
                        marginTop: 20,
                        minWidth: 180
                      }}
                      onClick={() => navigate("/carrito?select_program=1")}
                    />
                  </div>
                ) : (
                  <>
                    <div className='flex items-center gap-6 bg-Orchid px-8 py-2 rounded-xl'>
                      <div className='inline-block bg-white rounded-full p-2'>
                        <Icon04 width={20} />
                      </div>
                      <p className='text-white'>
                        {state.suscripcion_seleccionada?.id && (
                          <span className='font-golos-semibold uppercase'>{(`${state.suscripcion_seleccionada?.Program?.name} ${state.suscripcion_seleccionada?.typePlan || ""}`)?.trim()}.</span>
                        )}
                        <span className='ml-1 break-word'>Bienvenida, sigue los pasos.</span>
                      </p>
                    </div>

                    <div className='bg-white px-8 py-6 space-y-6 rounded-xl'>
                      {(isEmpty(state.video_introduccion?.id)) ? (
                        <Loading />
                      ) : (
                        <>
                          <div className='flex items-center gap-6'>
                            <div className='inline-block bg-Magnolia rounded-full px-[0.82rem] py-[0.25rem]'>
                              <p className='font-golos-bold text-Crayola text-lg'>1</p>
                            </div>
                            <p className='text-Crayola'>Ve el video de introducción para que puedas entender cómo funciona el programa.</p>
                          </div>
                          <div ref={(el) => elementsRef.current.push(el)} className='animate__animated relative flex items-center justify-center' data-animation={"animate__fadeIn"}>
                            <Image
                              src={getImageWeserv(state.portada_video_introduccion)}
                              alt="Portada del video de introducción"
                              className='bg-white border border-gray-100 flex justify-center items-center mx-auto w-full rounded-xl min-h-[140px] max-h-[460px]'
                              imageClassName='object-cover rounded-xl'
                              imageStyle={{ height: "100%", minHeight: 140, maxHeight: 460, width: "100%" }}
                            />
                            <div className='absolute c-pointer active:scale-90 transition ease-in-out' onClick={() => setState({ isVideoVisible: true })}>
                              <Image src={require('../../assets/images/play-btn.png')} alt="icon play" imageClassName='mx-auto max-w-[50px] md:max-w-[68px] lg:max-w-[86px] transition ease-in-out' imageStyle={{ width: "90%" }} />
                            </div>
                          </div>
                        </>
                      )}
                    </div>

                    <div className='flex flex-col xs:flex-row items-center justify-center xs:justify-between gap-6 bg-Crayola px-8 py-3 rounded-xl'>
                      <div className='flex items-center gap-6'>
                        <div className='inline-block bg-Magnolia rounded-full px-[0.82rem] py-[0.25rem]'>
                          <p className='font-golos-bold text-Crayola text-lg'>2</p>
                        </div>
                        <p className='text-white'>Únete a mi comunidad de alumnas en Facebook para resolver dudas</p>
                      </div>
                      <div>
                        <ButtonPrimary
                          text="¡Únete ahora!"
                          withIcon
                          icon={{
                            fill: "#df9cd4"
                          }}
                          classNameContainer="text-Orchid"
                          classNameButton="text-Orchid text-xs"
                          theme="white"
                          onClick={() => window.open(socialMedia.facebook_comunidad)}
                        />
                      </div>
                    </div>

                    <div className='bg-white px-8 py-6 space-y-6 rounded-xl'>
                      <div className='flex items-center gap-6'>
                        <div className='inline-block bg-Magnolia rounded-full px-[0.82rem] py-[0.25rem]'>
                          <p className='font-golos-bold text-Crayola text-lg'>3</p>
                        </div>
                        <p className='text-Crayola'>{getMessage(state.suscripcion_seleccionada?.Program?.code)}</p>
                      </div>
                      {["fitmom-embarazo"].includes(state.suscripcion_seleccionada?.Program?.code) && (
                        <div
                          ref={(el) => elementsRef.current.push(el)}
                          data-animation={"animate__fadeIn"}
                          className='animate__animated grid grid-cols-1 sm:grid-cols-2 gap-4'
                        >
                          <div className='relative rounded-xl min-h-[120px]'>
                            <Image
                              src={require('../../assets/images/entrenamientocore.png')}
                              alt="Entrenamiento de core"
                              className='flex justify-center items-center mx-auto h-full w-full rounded-xl'
                              imageClassName='object-cover rounded-xl'
                              imageStyle={{ height: "100%", width: "100%" }}
                            />
                            <div className='bg-gradient-to-t from-CrayolaHover h-[60%] xs:h-[50%] absolute bottom-0 left-0 w-full rounded-b-xl z-[1] pointer-events-none' />
                            <div className='absolute bottom-0 left-0 w-full z-10'>
                              <div className='relative flex items-center gap-2 ml-3 mb-2 z-[2]'>
                                <div className='flex flex-1 items-center gap-2 py-2'>
                                  <Icon01 fill={"#fff"} height={20} width={20} />
                                  <p className='font-golos-semibold text-white text-md break-word'>Entrenamiento de core</p>
                                </div>
                                <div className='px-3 py-2 c-pointer active:scale-90 transition ease-in-out' onClick={() => navigate(`/mi-cuenta/cores`)}>
                                  <FlechaIcon height={17} />
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className='relative rounded-xl min-h-[120px]'>
                            <Image
                              src={require('../../assets/images/entrenamientodia.png')}
                              alt="Entrenamiento del día"
                              className='flex justify-center items-center mx-auto h-full w-full rounded-xl'
                              imageClassName='object-cover rounded-xl'
                              imageStyle={{ height: "100%", width: "100%" }}
                            />
                            <div className='bg-gradient-to-t from-CrayolaHover h-[60%] xs:h-[50%] absolute bottom-0 left-0 w-full rounded-b-xl z-[1] pointer-events-none' />
                            <div className='absolute bottom-0 left-0 w-full z-10'>
                              <div className='relative flex items-center gap-2 ml-3 mb-2 z-[2]'>
                                <div className='flex flex-1 items-center gap-2 py-2'>
                                  <Icon03 fill={"#fff"} height={20} width={20} />
                                  <p className='font-golos-semibold text-white text-md break-word'>Entrenamiento del día</p>
                                </div>
                                <div className='px-3 py-2 c-pointer active:scale-90 transition ease-in-out' onClick={() => navigate(`/mi-cuenta/tu-programa`)}>
                                  <FlechaIcon height={17} />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                      {["fitmom-posparto"].includes(state.suscripcion_seleccionada?.Program?.code) && (
                        <div
                          ref={(el) => elementsRef.current.push(el)}
                          data-animation={"animate__fadeIn"}
                          className='animate__animated grid grid-cols-1 sm:grid-cols-2 gap-4'
                        >
                          <div className='relative rounded-xl'>
                            <Image
                              src={require('../../assets/images/etapa1.png')}
                              alt="Etapa 1"
                              className='flex justify-center items-center mx-auto h-full w-full rounded-xl'
                              imageClassName='object-cover rounded-xl'
                              imageStyle={{ height: "100%", width: "100%" }}
                            />
                            <div className='bg-gradient-to-t from-CrayolaHover h-[60%] xs:h-[50%] absolute bottom-0 left-0 w-full rounded-b-xl z-[1] pointer-events-none' />
                            <div className='absolute bottom-0 left-0 w-full z-10'>
                              <div className='relative flex items-center gap-2 ml-3 mb-2 z-[2]'>
                                <div className='flex flex-1 items-center gap-2 py-2'>
                                  <EtapaIcon fill={"#fff"} height={18} width={18} />
                                  <p className='font-golos-semibold text-white text-md break-word'>ETAPA 1</p>
                                </div>
                                <div className='px-3 py-2 c-pointer active:scale-90 transition ease-in-out' onClick={() => state.etapas?.etapa_1 && navigate(`/mi-cuenta/cores/category/${state.etapas?.etapa_1}`)}>
                                  <FlechaIcon height={17} />
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className='relative rounded-xl'>
                            <Image
                              src={require('../../assets/images/etapa2.png')}
                              alt="Etapa 2"
                              className='flex justify-center items-center mx-auto h-full w-full rounded-xl'
                              imageClassName='object-cover rounded-xl'
                              imageStyle={{ height: "100%", width: "100%" }}
                            />
                            <div className='bg-gradient-to-t from-CrayolaHover h-[60%] xs:h-[50%] absolute bottom-0 left-0 w-full rounded-b-xl z-[1] pointer-events-none' />
                            <div className='absolute bottom-0 left-0 w-full z-10'>
                              <div className='relative flex items-center gap-2 ml-3 mb-2 z-[2]'>
                                <div className='flex flex-1 items-center gap-2 py-2'>
                                  <EtapaIcon fill={"#fff"} height={18} width={18} />
                                  <p className='font-golos-semibold text-white text-md break-word'>ETAPA 2</p>
                                </div>
                                <div className='px-3 py-2 c-pointer active:scale-90 transition ease-in-out' onClick={() => navigate(`/mi-cuenta/tu-programa`)}>
                                  <FlechaIcon height={17} />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                      {["fitmom-strength"].includes(state.suscripcion_seleccionada?.Program?.code) && (
                        <div ref={(el) => elementsRef.current.push(el)} data-animation={"animate__fadeIn"} className='animate__animated relative flex items-center justify-center pb-4'>
                          <VimeoPlayerInline
                            portada={getImageWeserv(state.video_hoy?.image)}
                            urlVideo={state.video_hoy?.urlVideo}
                          />
                        </div>
                      )}
                    </div>

                    <div className='bg-Orchid relative rounded-xl lg:mt-10'>
                      <div className="xl:container mx-auto px-6">
                        <div className='flex flex-wrap md:flex-nowrap items-center justify-center gap-10'>
                          <div ref={(el) => elementsRef.current.push(el)} className='hidden md:flex self-end animate__animated -mt-[2em]' data-animation={"animate__fadeIn"}>
                            <Image src={require("../../assets/images/home/banner-img2.png")} alt="portada banner" imageClassName='max-w-[260px] md:max-w-[300px] lg:max-w-[340px]' />
                          </div>
                          <div className='flex flex-col py-8'>
                            <div className='md:max-w-[520px]'>
                              <h1 ref={(el) => elementsRef.current.push(el)} data-animation={"animate__slideInDown"} className='text-DeepKoamaru text-2xl lg:text-3xl mb-2 text-center lg:text-left animate__animated'>
                                Comienza a cuidar de tu <span className='font-golos-bold'>salud emocional</span>
                              </h1>
                            </div>
                            <div className='flex flex-col lg:flex-row items-center gap-8'>
                              <p data-animation={"animate__slideInUp"} className='max-w-[420px] lg:max-w-[260px] text-white text-[14px] text-center lg:text-left'>En OpcionYo tu paz interior es nuestra prioridad. <span className='font-golos-bold'>Terapia online recomendada por Jimena Cantú</span></p>
                              <ButtonPrimary
                                text="Terapia online"
                                withIcon
                                theme="white"
                                onClick={() => window.location.href = externalLinks.opcionYo}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <CarouselProductosRecomendados />
                  </>
                )}
              </div>
            )}
          </Grid>
        </div>
      </div>
    </>
  );
};

export default MiCuenta;