export const getMotivosCancelacion = async () => {
  return [
    {
      id: 1,
      name: "Dificultad para organizar el tiempo",
      value: "Dificultad para organizar el tiempo",
      customDesign: true,
      textButtonRedirect: "Ir a rutinas cortas"
    },
    {
      id: 2,
      name: "Razones económicas",
      value: "Razones económicas",
      textButtonRedirect: "Obtener descuento",
    },
    {
      id: 3,
      name: "Por instrucción médica",
      value: "Por instrucción médica",
      customDesign: true,
      textButtonRedirect: "Embarazo en reposo"
    },
    {
      id: 4,
      name: "Regreso al gym (otro tipo de ejercicio)",
      value: "Regreso al gym (otro tipo de ejercicio)",
      customDesign: true,
      textButtonRedirect: "Empezar $Programa",
      isValid: (programCode) =>
        programCode?.toLowerCase().includes("embarazo") ||
        programCode?.toLowerCase().includes("posparto"),
    },
    {
      id: 5,
      name: "No era lo que buscaba",
      value: "No era lo que buscaba",
      textButtonRedirect: "Obtener descuento",
    },
    {
      id: 6,
      name: "Problemas con el app",
      value: "Problemas con el app"
    },
    {
      id: 7,
      name: "Otro",
      value: "Otro"
    },
  ];
}