import React, { useCallback, useEffect, useMemo } from 'react';
import DataTable from 'react-data-table-component';
import SEO from '../../../components/SEO';
import { ButtonCustom } from '../../../components/Button';
import Loading from '../../../components/Loading';
import { useCustomState } from '../../../hooks/useCustomState';
import Grid from '../components/Grid';
import { getReviews, exportReviews } from '../../../api/admin/review';
import { paginationComponentOptions, Checkbox, NoDataComponent, SortIcon } from '../../../config/table.config';
import customTableStyles from '../../../styles/customTableStyles';
import downloadFile from '../../../utils/downloadFile';
import onSearchFields from '../../../utils/onSearchFields';
import dayjs from 'dayjs';

const CalificarVideos = () => {
  const [state, setState] = useCustomState({
    exports_loading: false,
    sending: false,
    loading: true,
    buscador: "",
    selectedRows: [],
    reviews: [],
  });

  useEffect(() => {
    loadData();
    // eslint-disable-next-line
  }, []);

  const loadData = useCallback(async () => {
    setState({ loading: true });
    const reviews = await getReviews();

    setState({
      reviews: reviews?.data,
      loading: false,
    });
  }, [setState]);

  const { columnsTable, filteredItems } = useMemo(() => ({
    columnsTable: [
      {
        compact: true,
        grow: 0,
        width: "0px",
        style: {
          borderLeft: "1px solid #eee",
          padding: 0,
        },
      },
      {
        name: 'Nombre de clienta',
        sortable: true,
        fixed: 'left',
        minWidth: '150px',
        selector: row => (row?.User?.Client?.fullName),
        cell: row => (row?.User?.Client?.fullName)
      },
      {
        name: 'Nombre de vídeo',
        sortable: true,
        fixed: 'left',
        minWidth: '150px',
        selector: row => {
          const _videoName = (row?.Workout || row?.Core || row?.GaleriaVideo)?.title;

          return _videoName;
        },
        cell: row => {
          const _video = (row?.Workout || row?.Core || row?.GaleriaVideo);

          return <a className='underline' href={_video?.urlVideo || ""} target="_blank" rel="noreferrer">{_video?.title || ""}</a>;
        }
      },
      {
        name: 'Calificación',
        sortable: true,
        fixed: 'left',
        // minWidth: '100px',
        selector: row => (row.score),
        cell: row => (`${row.scoreText}`)
      },
      {
        name: 'Reseña',
        sortable: true,
        fixed: 'left',
        minWidth: '150px',
        selector: row => (row.review),
        cell: row => row.review
      },
      {
        name: 'Fecha',
        sortable: true,
        fixed: 'left',
        minWidth: '180px',
        selector: row => {
          return row.createdAt && dayjs(row.createdAt).format("MM/DD/YYYY");
        },
        cell: row => {
          return row.createdAt && dayjs(row.createdAt).format("MM/DD/YYYY");
        },
      },
    ],
    filteredItems: onSearchFields(state?.reviews, {
      searchText: state.buscador,
      fieldsToSearch: ['User.Client.fullName', 'Workout.title', 'Core.title', 'GaleriaVideo.title', 'scoreText'],
    })
  }), [state.reviews, state.buscador]);

  const descargarReporte = useCallback(async () => {
    setState({ exports_loading: true });

    try {
      const response = await exportReviews({ id: state.selectedRows?.map(item => (item?.id)) });
      await downloadFile(response, 'reporte_resenias.xlsx');
      setState({ exports_loading: false });
    } catch (error) {
      console.error('Error al descargar el archivo:', error);
      setState({ exports_loading: false });
    }
  }, [state.selectedRows, setState]);

  return (
    <>
      <SEO
        title="Reseñas de vídeos"
      />
      <Grid>
        <div className='bg-Magnolia h-full pb-4'>
          {state.loading ? (
            <Loading containerClassName="h-full" />
          ) : (
            <>
              <div className='bg-white px-6 lg:px-8 py-6 shadow-sm flex flex-wrap flex-col sm:flex-row items-center justify-center md:justify-between gap-4'>
                <h1 className='flex-1 text-Crayola font-golos-medium text-3xl text-center md:text-left'>Reseñas de vídeos</h1>
                <div className='inline-flex flex-col sm:flex-row flex-wrap justify-center lg:justify-start gap-3'>
                  <div className='flex-1 min-w-[180px] max-w-xs mx-auto'>
                    <input
                      type="search"
                      placeholder='Buscar'
                      className="bg-Magnolia border-0 focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 placeholder-gray-500 px-3 py-2 relative rounded-md text-sm text-gray-900 w-full transition-all"
                      value={state?.buscador}
                      onChange={(event) => setState({ buscador: event.target.value })}
                    />
                  </div>
                  <div className='flex-1 inline-flex justify-center flex-wrap xs:flex-nowrap gap-3'>
                    <ButtonCustom
                      text="Descargar .xls"
                      icon={<i className="pi pi-download" style={{ fontSize: '0.84rem', marginRight: 7 }}></i>}
                      loading={state.exports_loading}
                      classNameButton="whitespace-nowrap border-Crayola bg-Crayola hover:!bg-DeepKoamaru hover:!border-DeepKoamaru min-h-[38px]"
                      onClick={descargarReporte}
                    />
                  </div>
                </div>
              </div>
              <div className='px-6 lg:px-8 container_table mt-6' style={{ borderRadius: 10 }}>
                <DataTable
                  columns={columnsTable}
                  data={filteredItems}
                  striped={false}
                  progressPending={state.sending}
                  pagination={true}
                  responsive={true}
                  paginationComponentOptions={paginationComponentOptions}
                  subHeader={false}
                  selectableRows
                  onSelectedRowsChange={({ selectedRows }) => setState({ selectedRows })}
                  selectableRowsComponent={Checkbox}
                  customStyles={customTableStyles}
                  sortIcon={<SortIcon />}
                  noDataComponent={<NoDataComponent />}
                />
              </div>
            </>
          )}
        </div>
      </Grid>
    </>
  );
};

export default CalificarVideos;