import QueryString from "qs";
import { getProgramas } from "../../../api/client/programas";
import { getCategoriesWorkouts } from "../../../api/client/workouts";
import { store } from "../../../redux/store";
import DificultadOrganizarTiempo from "./DificultadOrganizarTiempo";
import EmbarazoEnReposo from "./EmbarazoEnReposo";
import RegresoAlGym from "./RegresoAlGym";

const componentMap = {
  1: DificultadOrganizarTiempo,
  3: EmbarazoEnReposo,
  4: RegresoAlGym,
};

/**
 * Renderiza un componente basado en el id proporcionado.
 *
 * @param {Object} props - Propiedades del componente.
 * @param {number} props.id - ID del componente a renderizar.
 * @param {string} [props.programCode] - Código del programa (opcional).
 * @returns {JSX.Element|null} El componente correspondiente o null si no se encuentra o no es válido.
 */
const CustomComponentCancelarSuscripcion = ({ id, programCode }) => {
  if (id === 4) {
    const isValidProgram = programCode?.toLowerCase().includes("embarazo") || programCode?.toLowerCase().includes("posparto");
    if (!isValidProgram) {
      return null;
    }
  }

  const Component = componentMap[id];
  return Component ? <Component programCode={programCode} /> : null;
};

/**
 * Función para manejar la acción basada en el ID.
 *
 * @param {number} id - ID de la acción a ejecutar.
 * @param {Object} navigate - Objeto de navegación (react-router).
 * @param {Object} [params={}] - Parámetros adicionales para la navegación.
 * @param {Object} [options={}] - Opciones adicionales.
 * @param {Function} [options.beforeAction] - Callback antes de ejecutar la acción.
 * @param {Function} [options.afterAction] - Callback después de ejecutar la acción.
 */
export const handleActionById = async (id, navigate, params = {}, { beforeAction, afterAction } = {}) => {
  const user = store.getState()?.app?.auth?.user;

  if (!user) {
    console.error("Usuario no autenticado");
    return;
  }

  const fetchCategories = async (filter) => {
    try {
      const response = await getCategoriesWorkouts({ programID: user?.programID });
      return response?.data?.find((item) => item?.name?.toLowerCase()?.includes(filter));
    } catch (error) {
      console.error("Error al obtener categorías:", error);
      return null;
    }
  };

  const actions = {
    1: async () => {
      const categoria = await fetchCategories("quick workouts");
      if (categoria?.id) {
        const isOnlyCategory = Array.isArray(categoria?.SubCategories) && categoria?.SubCategories?.length > 0 ? false : true;
        navigate(`/mi-cuenta/workouts/${isOnlyCategory ? "only_category" : "category"}/${categoria?.id}`);
      }
    },
    2: async () => {
      const _params = QueryString.stringify({
        discount_code: "10COMEBACK"
      });

      navigate(`/carrito?${_params}`);
    },
    3: async () => {
      const categoria = await fetchCategories("ejercicios para embarazo en reposo");
      if (categoria?.id) {
        const isOnlyCategory = Array.isArray(categoria?.SubCategories) && categoria?.SubCategories?.length > 0 ? false : true;
        navigate(`/mi-cuenta/workouts/${isOnlyCategory ? "only_category" : "category"}/${categoria?.id}`);
      }
    },
    4: async () => {
      if (user?.Program?.code?.toLowerCase().includes("embarazo") || user?.Program?.code?.toLowerCase().includes("posparto")) {
        const cicloSeleccionado = user?.subscription?.cyclePeriod === "month" && parseInt(user?.subscription?.cycleLimit) === 6 ? "semestral" : "mensual";

        try {
          const programas = await getProgramas();
          const programaSeleccionado = programas?.data?.find((item) =>
            item?.code?.toLowerCase()?.includes(user.Program.code.includes("embarazo") ? "posparto" : "strength")
          );

          if (programaSeleccionado) {
            const _params = QueryString.stringify({
              select_program: "true",
              programID: programaSeleccionado?.id,
              cycleType: cicloSeleccionado,
            });

            navigate(`/carrito?${_params}`);
          }
        } catch (error) {
          console.error("Error al obtener programas:", error);
        }
      } else {
        console.warn("El código del programa no es válido para esta acción.");
      }
    },
    5: async () => {
      const _params = QueryString.stringify({
        discount_code: "10COMEBACK"
      });

      navigate(`/carrito?${_params}`);
    },
  };

  if (actions[id]) {
    if (typeof beforeAction === "function") beforeAction();
    setTimeout(async _ => await actions[id](), 200);
    if (typeof afterAction === "function") afterAction();
  } else {
    console.warn("Acción no definida para el id:", id);
  }
};

export default CustomComponentCancelarSuscripcion;